<template>
    <b-card title="Sticker Configuration">
        <b-form>
            <b-row>
                <b-col md="4">
                    <b-form-group label="Sticker Type">
                        <vSelect></vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Default Printer">
                        <vSelect></vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Print Head Alignment">
                        <vSelect></vSelect>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <b-form-group label="Width">
                        <vSelect></vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Height">
                        <vSelect></vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Print Adjustment">
                        <vSelect></vSelect>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="8">
                    <table class="table-responsive">
                        <tr style="background-color: #dcdfe6;">
                            <th>Option</th>
                            <th>Print</th>
                            <th>Font</th>
                            <th>Align</th>
                            <th>Size</th>
                            <th>Style</th>
                        </tr>
                        <tr>
                            <td>
                                Barcode
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>

                            </td>

                        </tr>
                        <tr>
                            <td>
                                Booking No.
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Process
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Garment Name
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Customer Name
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Customer Address
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Color
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Remark
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                Due Date
                            </td>
                            <td>
                                <b-form-checkbox checked="true" name="check-button" switch>
                                </b-form-checkbox>
                            </td>
                            <td>
                                <vSelect value="Arial Black"></vSelect>
                            </td>
                            <td>
                                <vSelect value="Center"></vSelect>
                            </td>
                            <td>
                                <b-form-spinbutton id="demo-sb" v-model="value" min="8" max="30" />
                            </td>
                            <td>
                                <b-row class="px-1">
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        B
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        I
                                    </b-form-checkbox>
                                    <b-form-checkbox checked="false" name="check-button" class="px-2">
                                        U
                                    </b-form-checkbox>
                                </b-row>
                            </td>

                        </tr>
                    </table>
                </b-col>
                <b-col md="3" class="text-center">
                    <b-form-group label="Sticker Preview">
                        <b-img v-bind="mainProps" blank-color="#475F7B" rounded alt="STICKER QR"
                            class="d-inline-block mr-1 mb-1" />
                        <b-button variant="primary" block>Preview And Update</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-col class="px-1">
                <b-form-checkbox checked="true" name="check-button" switch>
                    Select To Bring Forward / Prepone Due Date On Sticker
                </b-form-checkbox>
                <b-form-checkbox checked="true" name="check-button" switch>
                    Is This Auto Cutter Printer
                </b-form-checkbox>
                <b-form-checkbox checked="false" name="check-button" switch>
                    Print Sticker One By One (Receive From Workshop Screen)
                </b-form-checkbox>
                <b-form-checkbox checked="true" name="check-button" switch>
                    Print Sticker One By One (Pending For Finishing Screen)
                </b-form-checkbox>
                <b-form-checkbox checked="true" name="check-button" switch>
                    Print Sticker One By One (Packing Sticker Screen)
                </b-form-checkbox>
            </b-col>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BForm, BFormInput, BFormGroup, BImg, BButton, BFormCheckbox, BFormSpinbutton
} from 'bootstrap-vue'
import vSelect from 'vue-select'


export default {
    components: {
        BCard, BCol, BRow, BForm, BFormInput, BFormGroup, BImg, BButton, BFormCheckbox, BFormSpinbutton, vSelect
    },
    data: () => ({
        mainProps: {
            blank: true,
            width: 200,
            height: 200,
            class: 'm1',
        },
    }),
}
</script>
<style>
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
</style>